<template>
  <vs-card style="margin-bottom: 2%">
    <div slot="header">
      <vs-row>
        <vs-col
          style="font-size: 15px"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="8"
        >
          <div v-show="untracked === true">
            <b>{{ userdata.phone_number }} - </b>
          </div>
          <div v-show="untracked === false">
            <b>{{ userdata.person_name }}</b>
          </div>
          <vs-chip color="danger" v-if="userdata.count > 1">{{
            userdata.count
          }}</vs-chip>
        </vs-col>
        <vs-col
          style="font-size: 12px"
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-w="4"
        >
          <vs-button
            color="warning"
            type="gradient"
            icon="local_phone"
            @click="open"
          ></vs-button>
          <vs-button
            v-if="userdata.person_name === null"
            style="margin-left: 2%"
            color="warning"
            type="gradient"
            icon="edit"
            @click="assignMissed"
          ></vs-button>
          <vs-button
            v-if="userdata.person_name !== null"
            style="margin-left: 2%"
            color="warning"
            type="gradient"
            icon="info"
            @click="openBigPop"
          ></vs-button>
        </vs-col>
      </vs-row>
    </div>
    <vs-row>
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="left"
        vs-w="3"
        >Time</vs-col
      >
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="left"
        vs-w="1"
        >:</vs-col
      >
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="left"
        vs-w="8"
      >
        <!-- <div v-show="untracked === false"> -->
        <p>{{ unixTimestampConvert(userdata.time) }}</p>
        <!-- </div> -->
      </vs-col>
    </vs-row>
  </vs-card>
</template>

<script>
import EventBus from "../eventbus.js";
import moment from "moment";
// import axios from "axios";
// import constants from "../../../constants.json";
export default {
  props: {
    userdata: {}
  },
  data() {
    return {
      untracked: false,
      untrackedPhone: ""
    };
  },
  mounted() {
    // console.log("something");
    console.log("dun",this.userdata);
    if (this.userdata.person_name === null) {
      this.untracked = true;
    }
  },
  methods: {
    assignMissed() {
      EventBus.$emit("open-untracked-details-popup", this.userdata);
    },
    openBigPop() {
      console.log("my data: ", this.userdata);
      if (this.$route.path == "/queue") {
        this.openBigPopup(this.userdata.mwb_id);
      } else {
        this.openBigPopup(this.userdata.mwb_id);
      }
      // if (this.userdata.level == "M7" && this.userdata.city == "Hyderabad") {
      //   if (this.userdata.courses.indexOf("CPA") != -1) {
      //     this.openCPAPopup(this.userdata);
      //     return;
      //   } else if (this.userdata.courses.indexOf("CMA") != -1) {
      //     this.openCMAPopup(this.userdata);
      //     return;
      //   }
      // }
      // this.openMWBPopup(this.userdata);
    },
    open() {
      // console.log(this.userdata);
      let lettype = "";
      if (this.untracked === true) {
        lettype = "clickToCallUntracked";
      } else if (this.untracked === false) {
        lettype = "clickToCallPusher";
      }
      EventBus.$emit("open-missed-call-popup", this.userdata, lettype);
    },
    unixTimestampConvert(value) {
      value = value / 1000;
      var dateString = moment.unix(value).format("DD-MMMM-YY h:mm");
      switch (dateString) {
        case "01/01/1970":
          return "";
        default:
          return dateString;
      }
    }
  }
};
</script>

<style></style>

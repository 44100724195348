<template>
  <vs-card style="margin-bottom:3%">
    <div slot="header">
      <vs-row>
        <vs-col
          style="font-size: 15px"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="9"
        >
          <b>{{ data.person_name }} - {{ data.level }}</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
          <svg
            v-if="data.mhp_type == 'Net Enquiry'"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="24"
            height="24"
            viewBox="0 0 172 172"
            style=" fill:#000000; margin-right: 4%"
          >
            <g
              fill="none"
              fill-rule="nonzero"
              stroke="none"
              stroke-width="1"
              stroke-linecap="butt"
              stroke-linejoin="miter"
              stroke-miterlimit="10"
              stroke-dasharray=""
              stroke-dashoffset="0"
              font-family="none"
              font-weight="none"
              font-size="none"
              text-anchor="none"
              style="mix-blend-mode: normal"
            >
              <path d="M0,172v-172h172v172z" fill="none"></path>
              <g fill="#3498db">
                <path
                  d="M35.088,167.184c-0.688,0 -1.376,-0.344 -2.064,-0.688c-1.032,-0.688 -1.72,-2.408 -1.376,-3.784l15.136,-56.416l-45.408,-36.808c-1.376,-0.688 -1.72,-2.408 -1.376,-3.784c0.344,-1.376 1.72,-2.408 3.096,-2.408l58.48,-3.096l20.984,-54.696c0.688,-1.032 2.064,-2.064 3.44,-2.064c1.376,0 2.752,1.032 3.096,2.064l20.984,54.696l58.48,3.096c1.376,0 2.752,1.032 3.096,2.408c0.344,1.376 0,2.752 -1.032,3.784l-45.408,36.808l15.136,56.416c0.344,1.376 0,2.752 -1.376,3.784c-1.032,0.688 -2.752,1.032 -3.784,0l-49.192,-31.648l-49.192,31.648c-0.688,0.688 -1.032,0.688 -1.72,0.688z"
                ></path>
              </g>
            </g>
          </svg>
          <vs-button
            color="warning"
            type="gradient"
            @click="openPopUp"
            icon="info"
          ></vs-button>
        </vs-col>
      </vs-row>
    </div>
    <div>
      <vs-row style="margin-bottom: 2%">
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="left"
          vs-align="flex-start"
          vs-w="3"
          >MHP Type</vs-col
        >
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="left"
          vs-align="flex-start"
          vs-w="1"
          >:</vs-col
        >
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="left"
          vs-w="8"
          >{{ data.mhp_type }}</vs-col
        >
      </vs-row>
      <vs-row style="margin-bottom: 2%">
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="left"
          vs-align="flex-start"
          vs-w="3"
          >MHP Source</vs-col
        >
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="left"
          vs-align="flex-start"
          vs-w="1"
          >:</vs-col
        >
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="left"
          vs-w="8"
          >{{ data.mhp_source }}</vs-col
        >
      </vs-row>
      <vs-row style="margin-bottom: 2%">
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="left"
          vs-align="flex-start"
          vs-w="3"
          >Company</vs-col
        >
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="left"
          vs-align="flex-start"
          vs-w="1"
          >:</vs-col
        >
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="left"
          vs-w="8"
          >{{ data.company }}</vs-col
        >
      </vs-row>
      <vs-row style="margin-bottom: 2%">
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="left"
          vs-align="flex-start"
          vs-w="3"
          >Experience</vs-col
        >
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="left"
          vs-align="flex-start"
          vs-w="1"
          >:</vs-col
        >
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="left"
          vs-w="8"
          >{{ data.experience_int }}</vs-col
        >
      </vs-row>
      <vs-row>
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="left"
          vs-align="flex-start"
          vs-w="3"
          >Designation</vs-col
        >
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="left"
          vs-align="flex-start"
          vs-w="1"
          >:</vs-col
        >
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="left"
          vs-w="8"
          >{{ data.designation }}</vs-col
        >
      </vs-row>
    </div>
  </vs-card>
</template>

<script>
// import EventBus from "../eventbus.js";
// import axios from "axios";
// import constants from "../../../constants.json";
import moment from "moment";
export default {
  props: {
    data: {},
  },
  methods: {
    async openPopUp() {
      this.openBigPopup(this.data.id);
      // let emit = ""
      // emit = 'open-student-details-popup'
      // let url = `${constants.SERVER_API}getSingleMwbLead?can_id=${this.data.can_id}&person_id=${this.data.person_id}`;
      // axios
      //   .get(url, {
      //     headers: { Authorization: ` Bearer ${localStorage.userAccessToken}` }
      //   })
      //   .then(response => {
      //     this.$store.commit("UPDATE_NEW_DATA", [
      //       this.data,
      //       response.data.engagements,
      //       response.data.mobile,
      //       response.data.emails
      //     ]);
      //     EventBus.$emit("open-student-details-popup", this.data);
      //     EventBus.$emit("reload-dashboard");
      //   })
      //   .catch(error => {
      //     this.handleError(error);
      // if (error.response) {
      //   console.log("error", error.response.status);
      //   if (error.response.status === 401) {
      //     this.ReDirect_to_login();
      //   }
      // }
      // });
    },
    unixTimestampConvert(value) {
      var dateString = moment.unix(value).format("DD/MM/YYYY");
      switch (dateString) {
        case "01/01/1970":
          return "";
        default:
          return dateString;
      }
    },
  },
};
</script>

<style></style>

<template>
  <vs-card style="margin-bottom:3%">
    <div slot="header">
      <vs-row style="margin-bottom: 2%">
        <vs-col
          style="font-size: 15px"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="9"
        >
          <b>{{ data.person_name }}</b> -<b>{{ data.level }}</b> - <b>{{ data.source }}</b> -
          <b>{{ data.company }}</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
          <vs-button
            color="warning"
            type="gradient"
            @click="openPopUp"
            icon="info"
          ></vs-button>
        </vs-col>
      </vs-row>
    </div>
    <div>
      <vs-row style="margin-bottom: 2%">
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="right"
          vs-align="flex-start"
          vs-w="3"
          >Company:</vs-col
        >
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="9"
          >{{ data.company }}</vs-col
        >
      </vs-row>
      <vs-row style="margin-bottom: 2%">
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="right"
          vs-align="flex-start"
          vs-w="3"
          >Experience:</vs-col
        >
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="9"
          >{{ data.experience_int }}</vs-col
        >
      </vs-row>
      <vs-row>
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="right"
          vs-align="flex-start"
          vs-w="3"
          >Designation:</vs-col
        >
        <vs-col
          style="font-size: 13px"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="9"
          >{{ data.designation }}</vs-col
        >
      </vs-row>
    </div>
  </vs-card>
</template>

<script>
// import EventBus from "../eventbus.js";
// import axios from "axios";
// import constants from "../../../constants.json";
import moment from "moment";
export default {
  props: {
    data: {}
  },
  methods: {
    async openPopUp() {
      this.openBigPopup(this.data.id);
      // let emit = ""
      // emit = 'open-student-details-popup'
      // let url = `${constants.SERVER_API}getSingleMwbLead?can_id=${this.data.can_id}&person_id=${this.data.person_id}`;
      // axios
      //   .get(url, {
      //     headers: { Authorization: ` Bearer ${localStorage.userAccessToken}` }
      //   })
      //   .then(response => {
      //     this.$store.commit("UPDATE_NEW_DATA", [
      //       this.data,
      //       response.data.engagements,
      //       response.data.mobile,
      //       response.data.emails
      //     ]);
      //     EventBus.$emit("open-student-details-popup", this.data);
      //     EventBus.$emit("reload-dashboard");
      //   })
      //   .catch(error => {
      //     this.handleError(error);
      // if (error.response) {
      //   console.log("error", error.response.status);
      //   if (error.response.status === 401) {
      //     this.ReDirect_to_login();
      //   }
      // }
      // });
    },
    unixTimestampConvert(value) {
      var dateString = moment.unix(value).format("DD/MM/YYYY");
      switch (dateString) {
        case "01/01/1970":
          return "";
        default:
          return dateString;
      }
    }
  }
};
</script>

<style></style>

<template>
  <vs-card style="margin-bottom: 2%">
    <div slot="header">
      <vs-row style="margin-bottom: 2%">
        <vs-col
          style="font-size: 14px"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="9"
        >
          <b>{{ userdata.name }}</b>
          <vs-chip
            style="margin-left: 2%"
            color="danger"
            v-if="userdata.count > 1"
            >{{ userdata.count }}</vs-chip
          >
          <!-- <img
            style="margin-left: 2%"
            :src="getcalltypeicon(userdata.directory)"
            v-bind:alt="userdata.type"
          />-->
        </vs-col>
        <vs-col
          style="font-size: 15px"
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-w="3"
        >
          <vs-button
            color="warning"
            style="margin-right: 5px;"
            type="gradient"
            icon="local_phone"
            @click="calluntracked"
          ></vs-button>
          <vs-button
            color="warning"
            type="gradient"
            @click="openPopUp"
            icon="create"
            v-if="this.userdata.matched !== 1"
          ></vs-button>
          <vs-button
            color="warning"
            type="gradient"
            @click="openPopUpMatched"
            icon="info"
            v-if="this.userdata.matched === 1"
          ></vs-button>
        </vs-col>
      </vs-row>
    </div>
    <vs-row style="margin-bottom: 2%">
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="right"
        vs-align="flex-start"
        vs-w="6"
      >
        Phone:
        <span style="margin-left:5%" v-if="this.$route.path === '/queue'">{{ userdata.mobile[0] }}</span>
        <span style="margin-left:5%" v-else>{{ userdata.mobile }}</span>
      </vs-col>
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="right"
        vs-align="flex-start"
        vs-w="6"
      >
        Enquiry Date:
        <span style="margin-left:5%">{{ userdata.enquiry_date }}</span>
      </vs-col>
    </vs-row>
    <vs-collapse open-hover type="border">
      <vs-collapse-item>
        <div slot="header">Show Chat</div>
        <!-- <VuePerfectScrollbar
          class="chat-content-scroll-area border border-solid border-grey-light"
          :settings="settings"
          ref="chatLogPS"
        >-->
        <!-- <div class="chat__log" ref="chatLog"> -->
        <chatInterface :details="userdata"></chatInterface>
        <!-- </div> -->
        <!-- </VuePerfectScrollbar> -->
      </vs-collapse-item>
    </vs-collapse>
    <!-- <vs-row style="margin-bottom: 2%">
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="right"
        vs-align="flex-start"
        vs-w="3"
      >Details:</vs-col>
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="9"
      >ting</vs-col>
    </vs-row>-->
  </vs-card>
</template>

<script>
// import VuePerfectScrollbar from "vue-perfect-scrollbar";
import EventBus from "../eventbus.js";
import moment from "moment";
import axios from "axios";
import constants from "../../../constants.json";
import chatInterface from "./DashboardChatInterface.vue";
export default {
  props: {
    userdata: {},
  },
  components: {
    chatInterface,
    // VuePerfectScrollbar
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.7,
      },
    };
  },
  mounted() {
    console.log("userdata", this.userdata)
  },
  methods: {
    async calluntracked() {
      let response = await this.checkPermission();
      if (localStorage.getItem("role") !== "cm4") {
        if (
          this.$route.path !== "/queue" &&
          this.$route.path !== "/logout" &&
          this.$route.path !== "/UtilizationReport" &&
          this.$route.path !== "/receipts"
        ) {
          if (response === "no") {
            this.$vs.notify({
              title: "Permission denied",
              text: "Please contact admin!!!",
              color: "danger",
              icon: "error",
            });
            return;
          }
        }
      }
      let params = {};
      params.phone_number = this.userdata.dialingNumber;
      axios
        .get(constants.SERVER_API + "clickToCallUntracked", {
          params: params,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
            // "content-type": "application/x-www-form-urlencoded;charset=utf-8"
          },
        })
        .then(() => {
          this.open = false;
          if (this.userdata.matched !== 1) {
            EventBus.$emit("open-netEnquiries-details-popup", this.userdata);
          }
        })
        .catch((error) => {
          // if (error.response) {
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
        });
    },
    openPopUpMatched() {
      this.openBigPopup(this.userdata.mwb_id);
      // axios
      //   .get(
      //     `${constants.SERVER_API}getMwbLead?mwb_id=${this.userdata.mwb_id}`,
      //     {
      //       headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
      //     }
      //   )
      //   .then(response => {
      //     // console.log(response);
      //     response.data.data.engagements = [];
      //     response.data.data.mobile = [];
      //     response.data.data.emails = [];
      //     // console.log("before");
      //     this.$store.commit("MOUNT_MWB_LEADS", [response.data.data]);
      //     axios
      //       .get(
      //         `${constants.SERVER_API}getSingleMwbLead?can_id=${response.data.data.can_id}&person_id=${response.data.data.person_id}`,
      //         {
      //           headers: {
      //             Authorization: `Bearer ${localStorage.userAccessToken}`
      //           }
      //         }
      //       )
      //       .then(response2 => {
      //         this.$store.commit("UPDATE_NEW_DATA", [
      //           response.data.data,
      //           response2.data.engagements,
      //           response2.data.mobile,
      //           response2.data.emails
      //         ]);
      //         EventBus.$emit(
      //           "open-student-details-popup",
      //           this.$store.getters.getObjectMwbLead(response.data.data)
      //         );
      //         this.open = false;
      //       })
      //       .catch(error => {
      //         this.handleError(error);
      //       });
      //   })
      //   .catch(error => {
      //     this.handleError(error);
      //   });
    },
    openPopUp() {
      this.$store.commit("FLUSH_UNWANTED_LEADS");
      EventBus.$emit("open-netEnquiries-details-popup", this.userdata);
    },
    getcalltypeicon(type) {
      switch (type) {
        case "OUTGOING":
          return "https://img.icons8.com/color/20/000000/outgoing-call.png";
        case "INCOMING":
          return "https://img.icons8.com/color/20/000000/callback.png";
        case "MISSED":
          return "https://img.icons8.com/office/20/000000/missed-call.png";
      }
    },
    secondsToHms(d) {
      d = Number(d);
      let h = Math.floor(d / 3600);
      let m = Math.floor((d % 3600) / 60);
      let s = Math.floor((d % 3600) % 60);
      return (
        ("0" + h).slice(-2) +
        ":" +
        ("0" + m).slice(-2) +
        ":" +
        ("0" + s).slice(-2)
      );
    },
    unixTimestampConvert(value) {
      value = value / 1000;
      var dateString = moment.unix(value).format("DD-MMMM-YY h:mm");
      switch (dateString) {
        case "01/01/1970":
          return "";
        default:
          return dateString;
      }
    },
  },
};
</script>

<style></style>

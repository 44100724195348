<template>
  <vs-card style="margin-bottom: 2%">
    <div slot="header">
      <vs-row>
        <vs-col
          style="font-size: 15px"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="9"
        >
          <b>{{ userdata.person_name}} - {{ userdata.level }} - </b>
          <vs-chip color="danger" v-if="userdata.count > 1">{{
            userdata.count
          }}</vs-chip>
          <img
            style="margin-left: 2%"
            :src="getcalltypeicon(userdata.directory)"
            v-bind:alt="userdata.type"
          />
        </vs-col>
        <vs-col
          style="font-size: 12px"
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-w="3"
        >
          <vs-button
            color="warning"
            type="gradient"
            @click="openPopUp"
            icon="create"
          ></vs-button>
        </vs-col>
      </vs-row>
    </div>
    <vs-row style="margin-bottom: 2%">
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="left"
        vs-w="3"
        >Time</vs-col
      >
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="left"
        vs-w="1"
        >:</vs-col
      >
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="left"
        vs-w="8"
        >{{ unixTimestampConvert(userdata.time) }}</vs-col
      >
    </vs-row>
    <vs-row style="margin-bottom: 2%">
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="left"
        vs-w="3"
        >Duration</vs-col
      >
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="left"
        vs-w="1"
        >:</vs-col
      >
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="left"
        vs-w="8"
        >{{ secondsToHms(userdata.call_duration) }}</vs-col
      >
    </vs-row>
    <vs-row style="margin-bottom: 2%">
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="left"
        vs-w="3"
        >Source</vs-col
      >
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="left"
        vs-w="1"
        >:</vs-col
      >
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="left"
        vs-w="8"
        >{{ userdata.source }} - {{ userdata.source_details }}</vs-col
      >
    </vs-row>
    <vs-row style="margin-bottom: 2%">
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="left"
        vs-w="3"
        >Company</vs-col
      >
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="left"
        vs-w="1"
        >:</vs-col
      >
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="left"
        vs-w="8"
        >{{ userdata.company }}</vs-col
      >
    </vs-row>
  </vs-card>
</template>

<script>
// import EventBus from "../eventbus.js";
import axios from "axios";
import moment from "moment";
import constants from "../../../constants.json";
export default {
  props: {
    userdata: {},
  },
  data() {
    return {
      authorized: "",
    };
  },
  methods: {
    async openPopUp() {
      console.log("my data: ", this.userdata);
      this.openBigPopup(this.userdata.id);
      // if (this.userdata.level == "M7" && this.userdata.city == "Hyderabad") {
      //   if (this.userdata.courses.indexOf("CPA") != -1) {
      //     this.openCPAPopup(this.userdata);
      //     return;
      //   } else if (this.userdata.courses.indexOf("CMA") != -1) {
      //     this.openCMAPopup(this.userdata);
      //     return;
      //   }
      // }
      // this.openMWBPopup(this.userdata);
    },
    authAttempt() {
      axios.get(`${constants.SERVER_API}giveMeMyId`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userAccessToken")}`,
        },
      });
    },
    unixTimestampConvert(value) {
      value = value / 1000;
      var dateString = moment.unix(value).format("DD-MMMM-YY h:mm");
      switch (dateString) {
        case "01/01/1970":
          return "";
        default:
          return dateString;
      }
    },
    getcalltypeicon(type) {
      switch (type) {
        case "OUTGOING":
          return "https://img.icons8.com/color/20/000000/outgoing-call.png";
        case "INCOMING":
          return "https://img.icons8.com/color/20/000000/callback.png";
        case "MISSED":
          return "https://img.icons8.com/office/20/000000/missed-call.png";
        case "REJECTED":
          return "https://img.icons8.com/color/20/000000/call-disconnected.png";
      }
    },
    secondsToHms(d) {
      d = Number(d);

      let h = Math.floor(d / 3600);
      let m = Math.floor((d % 3600) / 60);
      let s = Math.floor((d % 3600) % 60);

      return (
        ("0" + h).slice(-2) +
        ":" +
        ("0" + m).slice(-2) +
        ":" +
        ("0" + s).slice(-2)
      );
    },
  },
};
</script>

<style></style>

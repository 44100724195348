<template>
  <vs-card style="margin-bottom: 2%">
    <div slot="header">
      <vs-row>
        <vs-col
          style="font-size: 14px"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="9"
        >
          <b>{{ data.phone_number }}</b>
          <vs-chip
            style="margin-left: 2%"
            color="danger"
            v-if="data.count > 1"
            >{{ data.count }}</vs-chip
          >
          <img
            style="margin-left: 2%"
            :src="getcalltypeicon(data.directory)"
            v-bind:alt="data.type"
          />
        </vs-col>
        <vs-col
          style="font-size: 15px"
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-w="3"
        >
          <vs-button
            color="warning"
            style="margin-right: 5px;"
            type="gradient"
            icon="local_phone"
            @click="calluntracked"
          ></vs-button>
          <vs-button
            color="warning"
            type="gradient"
            @click="openPopUp"
            icon="create"
          ></vs-button>
        </vs-col>
      </vs-row>
    </div>
    <vs-row style="margin-bottom: 2%">
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="left"
        vs-w="3"
        >Type</vs-col
      >
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="left"
        vs-w="1"
        >:</vs-col
      >
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="left"
        vs-w="8"
        >{{ data.directory }}</vs-col
      >
    </vs-row>
    <vs-row style="margin-bottom: 2%">
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="left"
        vs-w="3"
        >Duration</vs-col
      >
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="left"
        vs-w="1"
        >:</vs-col
      >
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="left"
        vs-w="8"
        >{{ secondsToHms(data.call_duration) }}</vs-col
      >
    </vs-row>
    <vs-row>
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="left"
        vs-w="3"
        >Time</vs-col
      >
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="left"
        vs-w="1"
        >:</vs-col
      >
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="left"
        vs-w="8"
        >{{ unixTimestampConvert(data.time) }}</vs-col
      >
    </vs-row>
  </vs-card>
</template>

<script>
import EventBus from "../eventbus.js";
import moment from "moment";
import axios from "axios";
import constants from "../../../constants.json";
export default {
  props: {
    data: {}
  },
  methods: {
    async calluntracked() {
      let response = await this.checkPermission();
      if (localStorage.getItem("role") !== "cm4") {
        if (
          this.$route.path !== "/queue" &&
          this.$route.path !== "/logout" &&
          this.$route.path !== "/UtilizationReport" &&
          this.$route.path !== "/receipts"
        ) {
          if (response === "no") {
            this.$vs.notify({
              title: "Permission denied",
              text: "Please contact admin!!!",
              color: "danger",
              icon: "error"
            });
            return;
          }
        }
      }
      let params = {};
      params.phone_number = this.data.phone_number;
      axios
        .get(constants.SERVER_API + "clickToCallUntracked", {
          params: params,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`
            // "content-type": "application/x-www-form-urlencoded;charset=utf-8"
          }
        })
        .then(() => {
          this.open = false;
          EventBus.$emit("open-untracked-details-popup", this.data);
          console.log("untracekd popup");
        })
        .catch(error => {
          // if (error.response) {
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
        });
    },
    openPopUp() {
      this.$store.commit("FLUSH_UNWANTED_LEADS");
      EventBus.$emit("open-untracked-details-popup", this.data);
    },
    getcalltypeicon(type) {
      switch (type) {
        case "OUTGOING":
          return "https://img.icons8.com/color/20/000000/outgoing-call.png";
        case "INCOMING":
          return "https://img.icons8.com/color/20/000000/callback.png";
        case "MISSED":
          return "https://img.icons8.com/office/20/000000/missed-call.png";
      }
    },
    secondsToHms(d) {
      d = Number(d);
      let h = Math.floor(d / 3600);
      let m = Math.floor((d % 3600) / 60);
      let s = Math.floor((d % 3600) % 60);
      return (
        ("0" + h).slice(-2) +
        ":" +
        ("0" + m).slice(-2) +
        ":" +
        ("0" + s).slice(-2)
      );
    },
    unixTimestampConvert(value) {
      value = value / 1000;
      var dateString = moment.unix(value).format("DD-MMMM-YY h:mm");
      switch (dateString) {
        case "01/01/1970":
          return "";
        default:
          return dateString;
      }
    }
  }
};
</script>

<style></style>
